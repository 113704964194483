import { secureRefSplitApi } from './ref-api';
import { RefData } from 'components';

const portsApi = secureRefSplitApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPortRef: build.query<RefData, { portId: string }>({
      query: ({ portId }) => ({
        url: `/port/${portId}`,
        method: 'GET'
      })
    })
  })
});

export const { useFetchPortRefQuery } = portsApi;
