import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@client';

export const daExportApi = createApi({
  baseQuery: axiosBaseQuery(),
  reducerPath: 'daExport_api',
  endpoints: (build) => ({
    triggerPortCallExportEvent: build.mutation<string, { portCallId: number }>({
      query: ({ portCallId }) => ({
        url: `/secured/port-call/trigger-export-event`,
        method: 'POST',
        params: { portCallId }
      })
    }),
    triggerProformaExportEvent: build.mutation<string, { proformaId: number }>({
      query: ({ proformaId }) => ({
        url: `/secured/proforma/trigger-export-event`,
        method: 'POST',
        params: { proformaId }
      })
    })
  })
});

export const {
  useTriggerPortCallExportEventMutation,
  useTriggerProformaExportEventMutation
} = daExportApi;
