import { axiosBaseQuery } from '@client';
import {
  EvaluationAvailableToRelateProforma,
  EvaluationAvailableToRelateProformaResponse
} from '@module/evaluation/model/EvaluationAvailableToRelateProformaResponse';
import { EvaluationResponse } from '@module/evaluation/model/EvaluationResponse';
import { SaveEvaluationValuesForm } from '@module/evaluation/model/SaveEvaluationValuesForm';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ExpenseEvaluationVerifyForm } from '@state/types/ExpenseEvaluationVerifyForm';
import { PsUtilities } from 'components';
import { ExpenseEvaluationResponse } from '@module/expense-evaluation/model/ExpenseEvaluationResponse';
import { addSelectedValueToLinkedPDAs, getSelectedPDAsIds } from './apiHelpers';
import {
  ExpenseEvaluationBudgetStatusResponse,
  ExpenseEvaluationBudgetStatusRequest,
  AdditionalPayment
} from '../../types';
import { TaskResponseState } from '@module/integration/task/model/TaskResponse';
import { EvaluationPaymentInstructionsForm } from '@module/evaluation/model/EvaluationPaymentInstructionsForm';
import { PDAExportResponse } from '@containers/common/da-tool/evaluation/BudgetStatus/GescoIntegrationInfo';

export const evaluationApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: 'secured/evaluation' }),
  tagTypes: ['BudgetStatus', 'BudgetCommit'],
  reducerPath: 'evaluation-api',
  endpoints: (build) => ({
    verifyExpenseEvaluation: build.mutation<
      ExpenseEvaluationResponse,
      {
        evaluationId: number;
        formValues: ExpenseEvaluationVerifyForm;
        attachments?: {
          principalsAccount: File[];
          ownersAccount: File[];
          charterersAccount: File[];
        };
      }
    >({
      query: ({ evaluationId, formValues, attachments }) => {
        const formData = PsUtilities.createFormRequestParts(
          formValues,
          attachments?.principalsAccount ?? []
        );

        if (attachments) {
          PsUtilities.addAttachmentsToForm(
            formData,
            attachments.ownersAccount,
            'ownersAccountAttachments'
          );

          PsUtilities.addAttachmentsToForm(
            formData,
            attachments.charterersAccount,
            'charterersAccountAttachments'
          );
        }

        return {
          url: `/${evaluationId}/verify-expense-evaluation`,
          data: formData,
          method: 'POST',
          headers: {
            'Content-Type': 'multipart/form-data;'
          },
          formData: true
        };
      }
    }),
    fetchLinkedPDAs: build.query<
      EvaluationAvailableToRelateProforma,
      { evaluationId: number; relationIds: Set<string> }
    >({
      query: ({ evaluationId }) => ({
        url: `/${evaluationId}/load-available-to-relate-proformas`,
        method: 'GET'
      }),
      transformResponse: (
        response: EvaluationAvailableToRelateProformaResponse,
        meta,
        arg
      ) => addSelectedValueToLinkedPDAs(response, arg.relationIds)
    }),
    submitLinkedPDAs: build.mutation<
      EvaluationResponse,
      {
        evaluationFromData: Omit<SaveEvaluationValuesForm, 'relationIds'>;
        selectedLinkedPDAs: EvaluationAvailableToRelateProforma;
      }
    >({
      query: ({ evaluationFromData, selectedLinkedPDAs }) => {
        const saveEvaluationValuesForm: SaveEvaluationValuesForm = {
          id: evaluationFromData.id,
          currency: evaluationFromData.currency,
          rate: evaluationFromData.rate,
          terminalId: evaluationFromData.terminalId,
          berthId: evaluationFromData.berthId,
          invoiceNumber: evaluationFromData.invoiceNumber,
          invoiceDate: evaluationFromData.invoiceDate,
          relationIds: getSelectedPDAsIds(selectedLinkedPDAs)
        };

        return {
          url: '/save-evaluation-values',
          data: saveEvaluationValuesForm,
          method: 'POST'
        };
      }
    }),

    fetchBudgetStatus: build.query<
      ExpenseEvaluationBudgetStatusResponse,
      { evaluationId: number }
    >({
      query: ({ evaluationId }) => ({
        url: `/${evaluationId}/budget-check`,
        method: 'GET'
      })
    }),
    performBudgetCheck: build.mutation<
      ExpenseEvaluationBudgetStatusResponse,
      ExpenseEvaluationBudgetStatusRequest
    >({
      query: ({ evaluationId, ...data }) => ({
        url: `/${evaluationId}/budget-check`,
        data: {
          ...data,
          evaluationId: evaluationId
        },
        method: 'POST'
      }),
      async onQueryStarted({ evaluationId }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            evaluationApi.util.updateQueryData(
              'fetchBudgetStatus',
              { evaluationId },
              () => data
            )
          );
        } catch {
          //
        }
      },
      invalidatesTags: ['BudgetCommit']
    }),

    fetchBudgetCommitStatus: build.query<
      Map<string, PDAExportResponse>,
      { evaluationId: number }
    >({
      query: ({ evaluationId }) => ({
        url: `/${evaluationId}/budget-commit`,
        method: 'GET'
      }),
      providesTags: (result, _error, arg) =>
        result
          ? [{ type: 'BudgetCommit' as const, id: arg.evaluationId }]
          : ['BudgetCommit']
    }),
    performBudgetCommit: build.mutation<
      Map<string, PDAExportResponse>,
      { evaluationId: number }
    >({
      query: ({ evaluationId }) => ({
        url: `/${evaluationId}/budget-commit`,
        data: {
          evaluationId: evaluationId
        },
        method: 'POST'
      }),
      invalidatesTags: (result, _error, arg) =>
        result
          ? [{ type: 'BudgetCommit' as const, id: arg.evaluationId }]
          : ['BudgetCommit']
    }),
    sendBudgetCommitReminder: build.mutation<
      void,
      { evaluationId: number; departmentName: string }
    >({
      query: ({ evaluationId, departmentName }) => ({
        url: `/${evaluationId}/budget-commit/send-reminder/department/${departmentName}`,
        data: {
          evaluationId: evaluationId,
          departmentName: departmentName
        },
        method: 'POST'
      })
    }),

    performRetryTask: build.mutation<{ state: TaskResponseState }, number>({
      query: (evaluationId) => ({
        url: `/${evaluationId}/retry-task`,
        method: 'POST'
      }),
      invalidatesTags: ['BudgetStatus']
    }),

    performGetTask: build.query<TaskResponseState, number>({
      query: (evaluationId) => ({
        url: `/${evaluationId}/get-task`,
        method: 'GET'
      })
    }),

    savePaymentInstructions: build.mutation<
      EvaluationResponse,
      EvaluationPaymentInstructionsForm
    >({
      query: ({ ...data }) => {
        return {
          url: '/save-payment-instructions',
          data,
          method: 'POST'
        };
      }
    }),

    submitApr: build.mutation<AdditionalPayment, AdditionalPayment>({
      query: ({ ...data }) => {
        return {
          url: '/apr-submit',
          data,
          method: 'POST'
        };
      }
    })
  })
});

export type StateWithEvaluationApi = {
  [evaluationApi.reducerPath]: ReturnType<typeof evaluationApi.reducer>;
};

export const {
  useVerifyExpenseEvaluationMutation,
  useFetchLinkedPDAsQuery,
  useSubmitLinkedPDAsMutation,
  useFetchBudgetStatusQuery,
  usePerformBudgetCheckMutation,
  usePerformRetryTaskMutation,
  usePerformGetTaskQuery,
  useSavePaymentInstructionsMutation,
  useSubmitAprMutation,
  useFetchBudgetCommitStatusQuery,
  usePerformBudgetCommitMutation,
  useSendBudgetCommitReminderMutation
} = evaluationApi;

export type fetchBudgetStatusResponse =
  typeof evaluationApi.endpoints.fetchBudgetStatus.Types.ResultType;

export const invalidateBudgetStatus = evaluationApi.util.invalidateTags([
  'BudgetStatus'
]);
