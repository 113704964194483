import { mainRestApi } from '@state/stores/restApi/rest-api';

export const featureFlagsApi = mainRestApi.injectEndpoints({
  endpoints: (build) => ({
    fetchFeatureFlagStatus: build.query<
      {
        featureFlagName: string;
        featureFlagStatus: boolean;
      }[],
      void
    >({
      query: () => ({
        url: `/feature-flags`,
        method: 'GET'
      })
    })
  })
});

export const { useFetchFeatureFlagStatusQuery } = featureFlagsApi;

export const useFlag = (flag: string): boolean => {
  const { data: flags } = useFetchFeatureFlagStatusQuery();

  return Boolean(
    (flags ?? []).find((f) => f.featureFlagName === flag)?.featureFlagStatus
  );
};
