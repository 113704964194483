import { RefData } from '@state';
import { portAnalysisApi } from './portAnalysisServiceApi';

const refDataPath = '/ref-data';

const refDataApi = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    fetchSegments: build.query<RefData[], void>({
      query: () => ({
        url: refDataPath + '/segments',
        method: 'GET'
      })
    }),
    fetchExpenseCategories: build.query<RefData[], void>({
      query: () => ({
        url: refDataPath + '/expense-categories',
        method: 'GET'
      })
    }),
    fetchExpenseSections: build.query<RefData[], void>({
      query: () => ({
        url: refDataPath + '/expense-sections',
        method: 'GET'
      })
    })
  }),
  overrideExisting: false
});

export const {
  useFetchSegmentsQuery,
  useFetchExpenseCategoriesQuery,
  useFetchExpenseSectionsQuery,
  useLazyFetchSegmentsQuery
} = refDataApi;
