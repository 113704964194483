import { PortFormRequestDto } from '@state';
import { portAnalysisApi } from './portAnalysisServiceApi';
import { PortAnalysisPortResponse } from '@state/types/PortAnalysisPortResponse';

const BASE_URL = '/secured/port';

const portAPI = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPort: build.query<PortAnalysisPortResponse, { portId: number }>({
      query: ({ portId }) => ({
        url: `${BASE_URL}/${portId}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_ANALYSIS_PORT' as const, id: arg.portId }]
          : ['PORT_ANALYSIS_PORT']
    }),
    savePort: build.mutation<PortAnalysisPortResponse, PortFormRequestDto>({
      query: (portData) => ({
        url: BASE_URL,
        method: 'POST',
        data: portData
      }),
      invalidatesTags: (res, _error, arg) =>
        res
          ? [
              { type: 'PORT_ANALYSIS_PORT' as const, id: res.id },
              'PORT_ANALYSIS_LOCATION'
            ]
          : ['PORT_ANALYSIS_PORT', 'PORT_ANALYSIS_LOCATION']
    })
  }),
  overrideExisting: false
});

export const { useFetchPortQuery, useLazyFetchPortQuery, useSavePortMutation } =
  portAPI;
