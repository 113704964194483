import { Auth0Provider } from '@auth0/auth0-react';
import { getEnvironmentVariables, useHotjar } from '@common';
import { createPreloadedState, setupStore } from '@state';
import { UpscopeProvider } from '@upscopeio/react';
import React, { ComponentType, PropsWithChildren, useEffect } from 'react';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import {
  useAutoBlockCookies,
  useCookieConsentBanner
} from '../hooks/useCookieConsentBanner';
import { useFontSize } from '../hooks/useFontSize';
import { useInspectlet } from '../hooks/useInspectlet';
import { useInitIntercom } from '../hooks/useIntercomApp';
import AuthWrapper from './AuthWrapper';

import { DrawerProvider } from '@components/Drawer/Drawer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useFetchFeatureFlagStatusQuery } from '@state/stores/restApi/other/featureFlagApi';
import PageLoader from '../../frontend/fragment/PageLoader';

const store = setupStore(createPreloadedState());
const persistor = persistStore(store);

const {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  HEAP_API_KEY,
  INTERCOM_APP_ID,
  FEATURE_FLAG_ENVIRONMENT,
  FEATURE_FLAG_TOKEN
} = getEnvironmentVariables();

const FlagsResolver = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const { isLoading, isUninitialized } = useFetchFeatureFlagStatusQuery();

  if (isUninitialized || isLoading) {
    return <PageLoader />;
  }

  return <>{children}</>;
};

const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', 'Arial', sans-serif"
  },
  palette: {
    primary: {
      main: '#0a6ebd',
      light: '#ecf6fe'
    }
  }
});

export const GlobalProviders = ({
  children
}: PropsWithChildren<{}>): JSX.Element => {
  const status = useAutoBlockCookies();

  useCookieConsentBanner(status);
  useHotjar(status);
  useInspectlet(status);
  useInitIntercom(status);
  useFontSize();

  useEffect(() => {
    if (status === 'ready') {
      HEAP_API_KEY && window.heap?.load(HEAP_API_KEY);
    }
  }, [status]);

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin + '/authorization'
      }}
    >
      <UpscopeProvider apiKey="i2qtqanZxq" enabled={status === 'ready'}>
        <AuthWrapper>
          <PersistGate loading={<PageLoader />} persistor={persistor}>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DrawerProvider>{children}</DrawerProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </PersistGate>
        </AuthWrapper>
      </UpscopeProvider>
    </Auth0Provider>
  );
};

export const withGlobalProviders = (WrappedComponent: ComponentType) => {
  const ComponentWithGlobalProviders = () => {
    return (
      <Provider store={store}>
        <FlagsResolver>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <GlobalProviders>
              <WrappedComponent />
            </GlobalProviders>
          </IntercomProvider>
        </FlagsResolver>
      </Provider>
    );
  };

  return ComponentWithGlobalProviders;
};
