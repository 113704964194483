import { SESSION, webStorage } from '@common';
import {
  configureStore,
  createListenerMiddleware,
  PreloadedState
} from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { middlewares, rootReducer } from './reducers';
import { persistedReducer } from './store-persist';
import { registerAgentAccountListeners } from './stores/agent-account/actionListeners';
import { registerAgentRatingActionListeners } from './stores/agent-rating';
import { registerSofListeners } from './stores/statement-of-facts/actionListeners';
import {
  authenticationSlice,
  AuthenticationState,
  initialAuthState,
  registerSessionListeners,
  sessionStateName
} from './stores/user';

export type RootState = ReturnType<typeof rootReducer>;

export const createPreloadedState = (): PreloadedState<RootState> => {
  const authState: AuthenticationState = {
    ...(webStorage.getItem<AuthenticationState>(SESSION) ?? initialAuthState)
  };

  return { [sessionStateName]: { [authenticationSlice.name]: authState } };
};

export const listenerMiddleware = createListenerMiddleware<RootState>();
registerSessionListeners(listenerMiddleware);
registerAgentRatingActionListeners(listenerMiddleware);
registerSofListeners(listenerMiddleware);
registerAgentAccountListeners(listenerMiddleware);

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
      })
        .prepend(listenerMiddleware.middleware)
        .concat(middlewares)
        .concat(thunkMiddleware)
  });
};
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
