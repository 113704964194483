import { createSelector } from '@reduxjs/toolkit';
import { fetchPortCallsResponse } from './api';
import { StateWithBuyerDashboard, buyerDashboardSlice } from './reducers';

export const selectBuyerDashboardState = (state: StateWithBuyerDashboard) =>
  state[buyerDashboardSlice.name];

export const selectBuyerDashboardPaginationState = (
  state: StateWithBuyerDashboard
) => selectBuyerDashboardState(state).pagination;

export const selectBuyerDashboardFilters = (state: StateWithBuyerDashboard) =>
  selectBuyerDashboardState(state).filters;

export const selectBuyerDashboardGrouping = (state: StateWithBuyerDashboard) =>
  selectBuyerDashboardState(state).grouping;

export const selectAppointmentDrawerState = (state: StateWithBuyerDashboard) =>
  selectBuyerDashboardState(state).drawer;

export const selectDrawerAppointmentId = (state: StateWithBuyerDashboard) =>
  selectAppointmentDrawerState(state)?.appointmentId;

export const selectDrawerPortCallId = (state: StateWithBuyerDashboard) =>
  selectAppointmentDrawerState(state)?.portCallId;

export const selectDrawerVoyageNumber = (state: StateWithBuyerDashboard) =>
  selectAppointmentDrawerState(state)?.voyageNumber;

const selectPortCallsResponse = createSelector(
  ({ data }: { data: fetchPortCallsResponse }) => data,
  (data) => data
);

const selectPortCalls = createSelector(
  selectPortCallsResponse,
  (data) => data.items
);

export const selectPortCallById = createSelector(
  (_res: { data: fetchPortCallsResponse }, id: number) => id,
  selectPortCalls,
  (id, portCalls) => (portCalls ?? []).find((p) => p.id === id)
);

export const selectPortCallAppointments = createSelector(
  selectPortCallById,
  (portCall) => portCall?.appointments ?? []
);

export const selectAppointmentById = createSelector(
  (
    _res: { data: fetchPortCallsResponse },
    portCallId: number,
    appointmentId: number
  ) => ({ portCallId, appointmentId }),
  selectPortCallAppointments,
  ({ appointmentId }, appointments) => {
    return appointments.find((appointment) => appointment.id === appointmentId);
  }
);

export const selectEvaluations = createSelector(
  (
    _res: { data: fetchPortCallsResponse },
    portCallId: number,
    appointmentId: number
  ) => ({ portCallId, appointmentId }),
  selectAppointmentById,
  (_, appointment) => appointment?.evaluations ?? []
);

export const selectEvaluation = createSelector(
  (
    _res: { data: fetchPortCallsResponse },
    portCallId: number,
    appointmentId: number,
    evaluationId: number
  ) => ({ portCallId, appointmentId, evaluationId }),
  selectEvaluations,
  ({ evaluationId }, evaluations) => {
    return evaluations?.find((e) => e.id === evaluationId);
  }
);

export const selectAgentRating = createSelector(
  (
    _res: { data: fetchPortCallsResponse },
    portCallId: number,
    appointmentId: number
  ) => ({ portCallId, appointmentId }),
  selectAppointmentById,
  (_, appointment) => {
    const criteriaRatings = appointment?.agentRating?.criteriaRatings ?? [];
    return (
      criteriaRatings.reduce((acc, { rating }) => acc + rating, 0) /
      (criteriaRatings.length || 1)
    );
  }
);
