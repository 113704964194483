import { ListenerMiddlewareInstance } from '@reduxjs/toolkit';
import { rootReducer } from '../../reducers';
import {
  portCallsApi,
  selectBuyerDashboardFilters,
  selectBuyerDashboardPaginationState
} from '../buyer-dashboard';
import { agentRatingApi } from './agentRatingApi';

export const registerAgentRatingActionListeners = (
  listenerMiddleware: ListenerMiddlewareInstance<ReturnType<typeof rootReducer>>
) => {
  // update portcall appointment rating after rating was submitted successfully
  listenerMiddleware.startListening({
    matcher: agentRatingApi.endpoints.submitRating.matchFulfilled,
    effect: ({ payload }, { getState, dispatch }) => {
      const state = getState();
      const pagination = selectBuyerDashboardPaginationState(state);
      const filters = selectBuyerDashboardFilters(state);

      dispatch(
        portCallsApi.util.updateQueryData(
          'fetchPortCalls',
          { ...pagination, filters },
          (draft) => {
            for (const portCall of draft.items) {
              const appointment = (portCall.appointments ?? []).find(
                (a) => a.id === payload.appointmentId
              );
              if (appointment) {
                appointment.agentRating = payload;
                break;
              }
            }
          }
        )
      );
    }
  });
};
