import { axiosBaseQuery } from '@client';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ActivityTypeResponse } from '../../types';
import { AppointmentActivityTypeConfigurationResponse } from '../../types/AppointmentActivityTypeConfigurationResponse';

export const activityTypeConfigApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: ['ActivityTypesConfig'],
  reducerPath: 'activity-types-configuration_api',
  endpoints: (build) => ({
    fetchActivityTypeConfig: build.query<
      AppointmentActivityTypeConfigurationResponse[],
      void
    >({
      query: () => ({
        url: `/secured/appointment/activity-types`,
        method: 'GET'
      })
    }),
    fetchAllActivityTypes: build.query<ActivityTypeResponse[], void>({
      query: () => ({ url: `/secured/activity-types/list-all`, method: 'GET' })
    })
  })
});

export const {
  useFetchActivityTypeConfigQuery,
  useFetchAllActivityTypesQuery
} = activityTypeConfigApi;
