import { createApi } from '@reduxjs/toolkit/query/react';
import ListResponse, {
  NullableListResponse
} from '../../../../types/ListResponse';
import {
  Appointment,
  DashboardPortCall,
  DashboardVoyage,
  Evaluation,
  PagingRequest
} from '../../types';
// todo investigate why we can not import from barrel
import { axiosBaseQuery, isBaseQueryError } from '@client';
import { AppointmentRecipientResponse } from '@module/appointment/model/AppointmentRecipientResponse';
import { AppointmentResponse } from '@module/appointment/model/AppointmentResponse';
import { DashboardHistoryActionDTO } from '@module/dashboard/model/DashboardHistoryAction';
import { PortCallResponse } from '@module/port-call/model/PortCallResponse';
import { PortCallTableItem } from '@module/port-call/model/PortCallTableItem';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { AssignEvaluationDTO, PortCallWithAppointment } from '../../types';
import { EvaluationVerificationDTO } from './EvaluationVerificationDTO';
import { BuyerDashboardGrouping, BuyerDashboardState } from './reducers';

export interface BuyerDashboardRequestOptions {
  page: number;
  pageSize: number;
  filters: BuyerDashboardState['filters'];
}

export const portCallsAdapter = createEntityAdapter<DashboardPortCall>();
export const appointmentsAdapter = createEntityAdapter<Appointment>();
export const evaluationAdapter = createEntityAdapter<Evaluation>();

export const portCallsApi = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'PortCalls',
    'Voyages',
    'Appointments',
    'History',
    'PortCallWithAppointment',
    'ImportedPortCalls'
  ],
  reducerPath: 'port-calls_api',
  endpoints: (build) => ({
    fetchPortCalls: build.query<
      ListResponse<DashboardPortCall>,
      BuyerDashboardRequestOptions
    >({
      query: ({ page, pageSize, filters }) => {
        const curated = Object.entries(filters).reduce((acc, [key, value]) => {
          if (typeof value === 'undefined' || value === null || value === '') {
            return { ...acc };
          }
          return { ...acc, [key]: value };
        }, {} as Partial<BuyerDashboardState['filters']>);

        return {
          url: `secured/dashboard/portCalls`,
          method: 'GET',
          params: {
            page: page,
            size: pageSize,
            ...curated
          }
        };
      },
      transformResponse: (
        response: NullableListResponse<DashboardPortCall>
      ) => {
        return { ...response, items: response.items ?? [] };
      },
      providesTags: ['PortCalls']
    }),
    fetchVoyages: build.query<
      ListResponse<DashboardVoyage>,
      BuyerDashboardRequestOptions
    >({
      query: ({ page, pageSize, filters }) => {
        const curated = Object.entries(filters).reduce((acc, [key, value]) => {
          if (typeof value === 'undefined' || value === null || value === '') {
            return { ...acc };
          }
          return { ...acc, [key]: value };
        }, {} as Partial<BuyerDashboardState['filters']>);
        return {
          url: `secured/dashboard/voyages`,
          method: 'GET',
          params: {
            page: page,
            size: pageSize,
            ...curated
          }
        };
      },
      transformResponse: (response: NullableListResponse<DashboardVoyage>) => {
        return { ...response, items: response.items ?? [] };
      },
      providesTags: ['Voyages']
    }),
    fetchAppointments: build.query<
      ListResponse<Appointment>,
      BuyerDashboardRequestOptions
    >({
      query: ({ page, pageSize, filters }) => {
        const curated = Object.entries(filters).reduce((acc, [key, value]) => {
          if (typeof value === 'undefined' || value === null || value === '') {
            return { ...acc };
          }
          return { ...acc, [key]: value };
        }, {} as Partial<BuyerDashboardState['filters']>);
        return {
          url: `secured/dashboard/appointments`,
          method: 'GET',
          params: {
            page: page,
            size: pageSize,
            ...curated
          }
        };
      },
      transformResponse: (response: NullableListResponse<Appointment>) => {
        return { ...response, items: response.items ?? [] };
      },
      providesTags: ['Appointments']
    }),
    fetchPortCall: build.query<PortCallResponse, { id: number }>({
      query: ({ id }) => ({
        url: `secured/port-call/${id}/load`,
        method: 'GET'
      })
    }),
    updatePortCall: build.mutation<
      PortCallResponse,
      Partial<DashboardPortCall> & Pick<DashboardPortCall, 'id'>
    >({
      query: (data) => ({
        url: `/secured/port-call/save-details`,
        method: 'POST',
        data
      }),
      transformErrorResponse: (error) => {
        return isBaseQueryError(error)
          ? error.data.message
          : 'Error while fetching/updating port call details';
      },
      invalidatesTags: ['PortCalls', 'Voyages']
    }),
    sendReminder: build.mutation<
      AppointmentResponse,
      {
        appointmentId: number;
        portCallId?: number;
        grouping?: BuyerDashboardGrouping;
      }
    >({
      query: ({ appointmentId }) => ({
        url: `/secured/appointment/${appointmentId}/send-reminder`,
        method: 'POST'
      }),
      invalidatesTags: ['PortCalls', 'Voyages', 'Appointments']
    }),
    fetchAppointmentRecipient: build.query<
      AppointmentRecipientResponse,
      number
    >({
      query: (appointmentId) => ({
        url: `/secured/appointments/${appointmentId}/appointment-recipient`,
        method: 'GET'
      })
    }),
    fetchFirstLevelEvaluationFlowDepartments: build.query<
      EvaluationVerificationDTO[],
      string
    >({
      query: (evaluationIds) => ({
        url: `/secured/evaluations/active-flow-step?evaluationIds=${evaluationIds}`,
        method: 'GET'
      })
    }),
    fetchAppointmentHistory: build.query<DashboardHistoryActionDTO[], number>({
      query: (appointmentId) => ({
        url: `/secured/dashboard/history/${appointmentId}`,
        method: 'GET'
      }),
      providesTags: (_result, _error, appointmentId) => [
        {
          type: 'History',
          id: appointmentId
        }
      ]
    }),
    assignEvaluation: build.mutation<{}, AssignEvaluationDTO>({
      query: (data) => ({
        url: `/secured/evaluation/assign`,
        method: 'POST',
        data
      }),
      invalidatesTags: (_result, _error, { appointmentId }) => [
        { type: 'History', id: appointmentId }
      ]
    }),
    fetchPortcallWithAppointment: build.query<
      PortCallWithAppointment | null,
      { portcallId: number }
    >({
      query: ({ portcallId }) => ({
        url: `/secured/port-calls-with-appointment/${portcallId}`,
        method: 'GET'
      }),
      providesTags: ['PortCallWithAppointment']
    }),
    portcallChangeVoyage: build.mutation<
      PortCallWithAppointment | null,
      { portcallId: number; voyageId: number }
    >({
      query: (data) => ({
        url: `/secured/port-call-change-voyage`,
        method: 'POST',
        data
      }),
      invalidatesTags: ['PortCallWithAppointment']
    }),
    fetchImportedPortCalls: build.query<
      { items: PortCallTableItem[] },
      PagingRequest
    >({
      query: (pageRequest) => ({
        url: `/secured/buyer/da/dashboard/port-calls/imported`,
        method: 'POST',
        data: pageRequest
      }),
      providesTags: ['ImportedPortCalls']
    })
  })
});

const updateEvalutionAssignee = (
  { assign, profile }: AssignEvaluationDTO,
  evaluation: Evaluation
) => {
  if (!profile) {
    return;
  }

  const patch = assign
    ? {
        assigneeId: profile.id,
        assignee: {
          id: profile.id,
          fullName: profile.fullName,
          role: profile.role,
          department: {
            label: profile.department.id,
            value: profile.department.name
          }
        }
      }
    : {
        assigneeId: null,
        assignee: null
      };

  Object.assign(evaluation, patch);
};

export const updateVoyageAssignee = (
  assignEvaluationDto: AssignEvaluationDTO,
  queryArgs: BuyerDashboardRequestOptions
) => {
  const { evaluationId, profile } = assignEvaluationDto;

  return portCallsApi.util.updateQueryData(
    'fetchVoyages',
    queryArgs,
    (draft) => {
      const portCalls = draft.items.reduce((acc, cur) => {
        acc.push(...cur.portCalls);
        return acc;
      }, [] as DashboardPortCall[]);
      const appointments = portCalls.reduce((acc, cur) => {
        acc.push(...(cur.appointments ?? []));
        return acc;
      }, [] as Appointment[]);
      const evaluations = appointments.reduce((acc, cur) => {
        acc.push(...(cur.evaluations ?? []));
        return acc;
      }, [] as Evaluation[]);

      const evaluation = evaluations?.find((e) => e.id === evaluationId);

      if (!evaluation || !profile) {
        return;
      }

      updateEvalutionAssignee(assignEvaluationDto, evaluation);
    }
  );
};

export const updatePortCallAssignee = (
  assignEvaluationDto: AssignEvaluationDTO,
  queryArgs: BuyerDashboardRequestOptions
) => {
  const { evaluationId, profile } = assignEvaluationDto;

  return portCallsApi.util.updateQueryData(
    'fetchPortCalls',
    queryArgs,
    (draft) => {
      const appointments = draft.items.reduce((acc, cur) => {
        acc.push(...(cur.appointments ?? []));
        return acc;
      }, [] as Appointment[]);
      const evaluations = appointments.reduce((acc, cur) => {
        acc.push(...(cur.evaluations ?? []));
        return acc;
      }, [] as Evaluation[]);

      const evaluation = evaluations?.find((e) => e.id === evaluationId);

      if (!evaluation || !profile) {
        return;
      }

      updateEvalutionAssignee(assignEvaluationDto, evaluation);
    }
  );
};

export const updateAppointmentAssignee = (
  assignEvaluationDto: AssignEvaluationDTO,
  queryArgs: BuyerDashboardRequestOptions
) => {
  const { evaluationId, profile } = assignEvaluationDto;
  return portCallsApi.util.updateQueryData(
    'fetchAppointments',
    queryArgs,
    (draft) => {
      const evaluations = draft.items.reduce((acc, cur) => {
        acc.push(...(cur.evaluations ?? []));
        return acc;
      }, [] as Evaluation[]);
      const evaluation = evaluations?.find((e) => e.id === evaluationId);

      if (!evaluation || !profile) {
        return;
      }

      updateEvalutionAssignee(assignEvaluationDto, evaluation);
    }
  );
};

export type StateWithPortCallsApi = {
  [portCallsApi.reducerPath]: ReturnType<typeof portCallsApi.reducer>;
};

export const {
  useLazyFetchPortcallWithAppointmentQuery,
  useFetchPortCallsQuery,
  useFetchVoyagesQuery,
  useFetchAppointmentsQuery,
  useLazyFetchPortCallQuery,
  useUpdatePortCallMutation,
  useSendReminderMutation,
  useLazyFetchAppointmentRecipientQuery,
  useFetchAppointmentRecipientQuery,
  useLazyFetchFirstLevelEvaluationFlowDepartmentsQuery,
  useFetchFirstLevelEvaluationFlowDepartmentsQuery,
  useFetchAppointmentHistoryQuery,
  useLazyFetchAppointmentHistoryQuery,
  useAssignEvaluationMutation,
  endpoints,
  usePortcallChangeVoyageMutation,
  useLazyFetchImportedPortCallsQuery,
  useFetchImportedPortCallsQuery
} = portCallsApi;

export type fetchPortCallsResponse =
  typeof portCallsApi.endpoints.fetchPortCalls.Types.ResultType;

export type fetchVoyagesResponse =
  typeof portCallsApi.endpoints.fetchVoyages.Types.ResultType;

export type fetchAppointmentsResponse =
  typeof portCallsApi.endpoints.fetchAppointments.Types.ResultType;

export type dashboardFetchResponse =
  | fetchPortCallsResponse
  | fetchVoyagesResponse
  | fetchAppointmentsResponse;

export const isPortCallsResponse = (
  data: dashboardFetchResponse | undefined
): data is fetchPortCallsResponse => {
  return (
    typeof data !== 'undefined' &&
    (data.items.length === 0 || data.items.some((i) => 'appointments' in i))
  );
};

export const isVoyagesResponse = (
  data: dashboardFetchResponse | undefined
): data is fetchVoyagesResponse => {
  return (
    typeof data !== 'undefined' &&
    (data.items.length === 0 ||
      (data.items.some((i) => 'voyageNumber' in i) &&
        !data.items.some((i) => 'appointments' in i)))
  );
};

export const isAppointmentsResponse = (
  data: dashboardFetchResponse | undefined
): data is fetchAppointmentsResponse => {
  return (
    typeof data !== 'undefined' &&
    (data.items.length === 0 ||
      (!data.items.some((i) => 'appointments' in i) &&
        !data.items.some((i) => 'voyageNumber' in i)))
  );
};

export const invalidateImportedPortCalls = portCallsApi.util.invalidateTags([
  'ImportedPortCalls'
]);
