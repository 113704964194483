import { ExpenseEstimationResponse } from '../../types/ExpenseEstimationResponse';
import { portAnalysisApi } from './portAnalysisServiceApi';

const BASE_URL = '/secured/expense-estimation';

const estimationApi = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    getEstimation: build.query<ExpenseEstimationResponse, number>({
      query: (estimationId) => ({
        url: BASE_URL,
        method: 'get',
        params: { expenseEstimationId: estimationId }
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [
              {
                type: 'PORT_ANALYSIS_EXPENSE_ESTIMATIONS' as const,
                id: res.expenseId
              }
            ]
          : ['PORT_ANALYSIS_EXPENSE_ESTIMATION']
    }),
    archiveEstimation: build.mutation<ExpenseEstimationResponse, number>({
      query: (estimationId) => ({
        url: `${BASE_URL}/${estimationId}`,
        method: 'delete'
      }),
      invalidatesTags: (res, _error, arg) =>
        res
          ? [
              {
                type: 'PORT_ANALYSIS_EXPENSE_ESTIMATIONS' as const,
                id: res.expenseId
              },
              ...res.locationIds.map((id) => ({
                type: 'PORT_ANALYSIS_EXPENSES' as const,
                id
              }))
            ]
          : ['PORT_ANALYSIS_EXPENSE_ESTIMATIONS', 'PORT_ANALYSIS_EXPENSES']
    })
  }),
  overrideExisting: false
});

export const {
  useGetEstimationQuery,
  useLazyGetEstimationQuery,
  useArchiveEstimationMutation
} = estimationApi;
