import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BuyerDashboardFilterPagination } from '../../types';

export enum BuyerDashboardGrouping {
  VOYAGE = 'VOYAGE',
  APPOINTMENT = 'APPOINTMENT',
  PORT_CALL = 'PORT_CALL'
}

export type BuyerDashboardState = {
  pagination: BuyerDashboardFilterPagination;
  filters: {
    vessel: string;
    port: string;
    appointmentId: string;
    agent: string;
    portCallId: string;
    regionId: string;
    assignee: string;
    voyageNumber: string;
    etaFromDate: string;
    etaToDate: string;
    etsFromDate: string;
    etsToDate: string;
    myPendingTasks: boolean;
    mySubmittedAppointments: boolean;
  };
  grouping: BuyerDashboardGrouping;
  drawer?: {
    portCallId?: number;
    appointmentId: number;
    voyageNumber?: string;
  };
};

export const initialDashboardState: BuyerDashboardState = {
  pagination: {
    page: 0,
    pageSize: 5
  },
  filters: {
    myPendingTasks: false,
    mySubmittedAppointments: false,
    vessel: '',
    port: '',
    appointmentId: '',
    regionId: '',
    agent: '',
    portCallId: '',
    voyageNumber: '',
    etaFromDate: '',
    etaToDate: '',
    etsFromDate: '',
    etsToDate: '',
    assignee: ''
  },
  grouping: BuyerDashboardGrouping.PORT_CALL
};

export const buyerDashboardSlice = createSlice({
  name: 'buyer-dashboard',
  initialState: initialDashboardState,
  reducers: {
    updatePage: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.pagination.page = payload.page;
    },
    updateFilter: (
      state,
      { payload }: PayloadAction<Partial<BuyerDashboardState['filters']>>
    ) => {
      state.filters = { ...state.filters, ...payload };
      // reset page
      state.pagination.page = 0;
    },
    updateGrouping: (
      state,
      { payload }: PayloadAction<{ grouping: BuyerDashboardGrouping }>
    ) => {
      state.grouping = payload.grouping;
      // reset page
      state.pagination.page = 0;
    },
    resetFilters: (state) => {
      state.filters = {
        myPendingTasks: false,
        mySubmittedAppointments: false,
        vessel: '',
        port: '',
        appointmentId: '',
        regionId: '',
        agent: '',
        portCallId: '',
        voyageNumber: '',
        etaToDate: '',
        etaFromDate: '',
        etsToDate: '',
        etsFromDate: '',
        assignee: ''
      };
      // reset page
      state.pagination.page = 0;
    },
    updateDrawer: (
      state,
      {
        payload
      }: PayloadAction<{
        portCallId?: number;
        appointmentId: number;
        voyageNumber?: string;
      }>
    ) => {
      state.drawer = payload;
    }
  }
});

export const {
  updatePage: updateBuyerDashboardPage,
  updateFilter: updateBuyerDashboardFilter,
  updateGrouping: updateBuyerDashboardGrouping,
  resetFilters: resetBuyerDashboardFilters,
  updateDrawer: updateBuyerDashboardDrawer
} = buyerDashboardSlice.actions;

export type StateWithBuyerDashboard = {
  [buyerDashboardSlice.name]: BuyerDashboardState;
};
